<template>
<!-- 审核和查看详情页 -->
  <div class="info">
    <backTitle :lable="type ==='E'?'要素落实审核':'要素落实管理'"></backTitle>
    <div></div>
    <auditdetails
      :filelist="filelist"
      :title="title"
      :titlecomfig="titlecomfig"
      :finReviewOpinionInfoVOS="finReviewOpinionInfoVO"
      :showStep="this.$route.query.showStep?false:true"
      processStatus="ELEMENT_IMPLEMENTATION"
      style="margin-top: 15px;height: calc(100% - 58px);"
      :type='type'
      :open='true'
      businessType="ysls"
    >
    </auditdetails>
  </div>
</template>
<script>
import { getDict } from '@/filters/fromDict'
import backTitle from '@/pages/business/components/backtitle.vue'
import auditdetails from '@/pages/business/components/auditdetails.vue'
import { factorApi } from '@/api/businessApi'
import Storage from '@/utils/storage'
export default {
  name: 'ImplementAudit',
  components: { auditdetails, backTitle },
  data () {
    return {
      type: 'E',
      finReviewOpinionInfoVO: [],
      title: '供应商：',
      titlecomfig: [
        {
          label: '业务编号：',
          value: ''
        },
        {
          label: '盖章方式：',
          value: '电子章'
        }
      ],
      activeid: '',
      filelist: [
        {
          label: '合同文件',
          key: 'htwj',
          child: [],
          id: '1'
        },
        { label: '内部资料', key: 'nbzl', child: [], id: '2' },
        { label: '客户资料', key: 'khzl', child: [], id: '3' },
        { label: '担保人资料', key: 'dbrzl', child: [], id: '4' },
        { label: '标的资产相关', key: 'bdzcxg', child: [], id: '5' },
        { label: '中登资料文件', key: 'zdzl', child: [], id: '6' },
        { label: '要素落实资料', key: 'ysls', child: [], id: '7' },
        { label: '其他', key: 'qtzl', child: [], id: '8' }
      ],
      activeNames: '1'
      // formConfig: [
      //   { id: 10, name: '审核通过' },
      //   { id: 30, name: '审核不通过' },
      //   { id: 20, name: '返回修改' }
      // ],
      // formConfig: [
      //   {
      //     label: '业务编号',
      //     prop: 'businessNo',
      //     rules: [{ required: false, validator: validbusinessNo, trigger: 'blur' }],
      //     attrs: {
      //       placeholder: '请输入',
      //       maxlength: 20
      //     }
      //   },
      //   {
      //     label: '供应商',
      //     prop: 'gysId',
      //     attrs: {
      //       options: content.gysData,
      //       filterable: true,
      //       selectedField: ['keyId', 'enterpriseName']
      //     },
      //     tag: BaseSelect
      //   }
      // ]
    }
  },
  computed: {
    api () {
      return factorApi
    }
  },
  methods: {
    handleClick () {},
    getdetail () {
      const params = {
        businessId: Storage.getSession('examine').businessId,
        instanceId: Storage.getSession('examine').instanceId
      }
      if (this.type === 'V') {
        params.businessId = Storage.getSession('examine').keyId
        params.instanceId = Storage.getSession('examine').instanceId
      }
      this.api.getFactorDetails(params).then((res) => {
        this.title = this.title + res.data.finBusuinessInfoVO.gysCompanyName
        this.titlecomfig[0].value = res.data.finBusuinessInfoVO.businessNo
        this.titlecomfig[1].value = getDict(
          'SUPPLIERS_SEAL_TYPE',
          res.data.finBusuinessInfoVO.suppliersSealType.toString()
        )

        if (res.data.finFileListInfoVO) {
          res.data.finFileListInfoVO.forEach((item) => {
            this.filelist.forEach((elem) => {
              if (item.fileGroupCode === elem.key) {
                elem.child.push(item)
              }
            })
          })
        }
        this.finReviewOpinionInfoVO = res.data.finReviewOpinionInfoVO
      })
    }
  },
  created () {
    if (this.$route.query.type) {
      this.type = this.$route.query.type
    }
    this.getdetail()
  }
}
</script>
<style lang="scss" scoped>
.info{
  height: 100%;
}
/deep/ .el-tabs--border-card {
  box-shadow: none;
  border: 0;
}

</style>
